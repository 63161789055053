import React from 'react'
import { useParams } from 'react-router-dom'

import Splash from './Splash'
import Cover from './Cover'
import Roster from './Roster'
import Standings from './Standings'
import Timer from './Timer'
import Event from './Event'

const overlays = {
    splash: Splash,
    cover: Cover,
    roster: Roster,
    standings: Standings,
    timer: Timer,
    event: Event
}

const Components = () => {
    const { component } = useParams()
    const Specified = overlays[component]
    return  <Specified />
}

export default Components
