import axios from 'axios'
import { ENDPOINT } from './env'

const service = async (entity, item, match=null) => {
    const resp = await axios.get(`${ENDPOINT}stream/${entity}/${item}${match ? '/'+match : ''}`)
    if(resp.data && !resp.data.error) {
        return resp.data
    } else {
        return null
    }
}

export default service
