import qs from 'qs'

const parseQuery = () => {
    const obj = qs.parse(window.location.search.replace('?', ''))
    return Object.keys(obj).reduce((acc, key) => {
        const splitted = key.split('_')
        if(!acc[splitted[0]]) { acc[splitted[0]] = {}}
        acc[splitted[0]][splitted[1]] = ["0", "1"].includes(obj[key]) ? obj[key] === "1" : obj[key]

        return acc
    }, {})
}

const injectQueryPath = (h, k, v) => {
    const current = qs.parse(window.location.search.replace('?', ''))
    if(v) {
        current[k] = v
    } else {
        delete current[k]
    }

    const result = Object.entries(current).length ? `?${qs.stringify(current)}` : ''
    //window.history.pushState(null, null, `${window.location.pathname}${result}`)
    h.push(`${window.location.pathname}${result}`)

    return
}

export { injectQueryPath, parseQuery }
