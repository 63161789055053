import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import AppContext from './ctx'

import Components from './Components'
import Controls from './Controls'
import Stream from './Stream'

import { injectQueryPath, parseQuery } from './query'

import './magic.css'
import './app.scss'
import './scales.scss'

import overrides from './overrides'

const App = () => {
    const [overlays, setOverlays] = useState(parseQuery())
    const [dataLayer, setDataLayer] = useState({})
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        setOverlays(parseQuery())
    }, [location])

    const pitchId = window.location.pathname.replace('/s/', '')
    const override = overrides[pitchId]

    return  <AppContext.Provider value={{history, overlays, setOverlays, dataLayer, setDataLayer}}>
                <div className={`overlay${override ? ' _overriden': ''}`}>
                    <Switch>
                        <Route path='/dev/:component'>
                            <Components />
                            <Controls />
                        </Route>

                        <Route path='/s/:pitchId'>
                            <Stream override={null} />
                            {window.location.host.includes('localhost') ? <Controls /> : null}
                        </Route>

                        <Route path='/'>
                            Navigation
                        </Route>
                    </Switch>
                </div>
            </AppContext.Provider>
}

export default App
