import React, { useContext, useEffect } from 'react'
import AppContext from '../../ctx'
import service from '../../service'

import './style.scss'

const renderLines = data => {
    const model = data.schema ? data.schema.split('-') : []
    let pull = [...data.players]

    const rows = model.reduce((acc, qty) => {
        acc.push(pull.slice(0, qty))
        pull = pull.slice(qty)

        return acc
    }, []).reverse()

    return rows.map((row, i) => (
        <div className='lineup-row' key={i}>
            {row.map((p, i) => (
                <div className={'lineup-row-item'} key={i}>
                    <div className='num'>{p.num}</div>
                    <div className='lineup-row-item_img'>
                        <img src={p.avatarUrl || ''} />
                    </div>
                    <div className='lineup-row-item_name'>{p.surname}</div>
                </div>
            ))}
        </div>
    ))
}

const Roster = () => {
    const ctx = useContext(AppContext)
    const { roster, cover } = ctx.overlays
    const { rosterData, coverData } = ctx.dataLayer

    useEffect(() => {
        if(roster && roster.state && roster.subj && !rosterData && coverData) {
            service('roster', roster.subj, cover.subj)
                .then(data => {
                    console.log(data)
                    if(data) {
                        ctx.setDataLayer({...ctx.dataLayer, rosterData: data})

                        setTimeout(() => {
                            ctx.setDataLayer({...ctx.dataLayer, rosterData: null})
                        }, 5000)
                    }
                })
        }
    }, [roster])

    const teamEntry = roster && coverData ? coverData[roster.subj] || null : null

    return  rosterData ? (
                <div className={`roster ${roster && roster.state ? '' : 'gone'}`}>
                    <div className='panel'>
                        <div className='top'>
                            {teamEntry ? [
                                <div className='emblem'>
                                    <img src={teamEntry.emblem} />
                                </div>,
                                <div className='team'>{teamEntry.name}</div>
                            ] : null}
                        </div>

                        <div className='lineup'>
                            <div className='pitch'></div>
                            <div className='lines'>
                                {renderLines(rosterData.lineup)}
                            </div>
                        </div>

                        <div className='bench'>
                            {rosterData.bench && rosterData.bench.length ? (
                                <div className='title'>Запасные</div>
                            ) : null}
                            {rosterData.bench && rosterData.bench.length ? (
                                <div className='list'>{rosterData.bench.map(p => `${p.surname}${p.name ? ' '+p.name.slice(0,1) : ''}`).join(', ')}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null
}

export default Roster
