import React, { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import AppContext from '../ctx'

import Splash from '../Components/Splash'
import Cover from '../Components/Cover'
import Timer from '../Components/Timer'
import Standings from '../Components/Standings'
import Event from '../Components/Event'
import Roster from '../Components/Roster'

import service from '../service'
import { injectQueryPath } from '../query'

import './style.scss'

const Stream = ({ override }) => {
    /*CONTEXT SECTION*/
    const ctx = useContext(AppContext)
    const { coverData } = ctx.dataLayer
    /*CONTEXT SECTION*/

    /*SELF REFS*/
    const matchRef = useRef()
    const streamRef = useRef()
    const widgetRef = useRef()
    const coverDataRef = useRef()
    /*SELF REFS*/

    const history = useHistory()
    const { pitchId } = useParams()

    /*EFFECTS SECTION*/
    useEffect(() => {
        //инициализация. Тут мы ничего не знаем про состояние питча
        injectQueryPath(history, 'splash_state', '1')
    }, [])

    useEffect(() => {
        //при появлении ид локации, проверим, есть ли на ней актуальный матч
        if(pitchId) {
            if(!window.location.search.includes('freeze')) {
                setTimeout(() => {
                    actualizeSlot()
                }, 2000)
            }
        }
    }, [pitchId])

    useEffect(() => {
        if(widgetRef.current) {
            const widget = widgetRef.current.split('_')

            injectQueryPath(history, `${widget[0]}_state`, '1')
            if(widget[1]) {
                injectQueryPath(history, `${widget[0]}_subj`, widget[1])
            }

            setTimeout(() => {
                injectQueryPath(history, `${widget[0]}_state`, '0')

                if(widget[1]) {
                    injectQueryPath(history, `${widget[0]}_subj`, null)
                }

                widgetRef.current = null
            }, 5000)
        }
    }, [widgetRef.current])

    useEffect(() => {
        if(coverData) {
            setTimeout(() => {
                coverDataRef.current = {...coverData}
            },500)
        }
    }, [coverData])
    /*EFFECTS SECTION*/

    const actualizeSlot = () => {
        service('pitchslot', pitchId)
            .then(data => {
                if(data && data.matchId) {
                    if(!matchRef || matchRef.current !== data.matchId) {
                        injectQueryPath(history, 'cover_subj', data.matchId)
                        injectQueryPath(history, 'cover_state', '1')
                        injectQueryPath(history, 'splash_state', '0')
                        matchRef.current = data.matchId
                        streamRef.current = data.stream
                        ctx.setDataLayer({...ctx.dataLayer, coverData: null})
                    } else if(matchRef && matchRef.current && matchRef.current === data.matchId) {
                        injectQueryPath(history, 'cover_state', data.stream && data.stream.onAir ? '0' : '1')
                        if(data.stream && data.stream.onAir) {
                            injectQueryPath(history, 'splash_state', '0')
                        }
                    }

                    if(coverDataRef && coverDataRef.current && data.stream && data.stream.widget && (!widgetRef || (widgetRef.current !== data.stream.widget))) {
                        widgetRef.current = data.stream.widget
                    }
                } else {
                    injectQueryPath(history, 'cover_state', '0')
                    injectQueryPath(history, 'cover_subj', 0)
                    injectQueryPath(history, 'splash_state', '1')
                }

                setTimeout(() => {
                    actualizeSlot()
                }, 3000)
            })
    }

    return  [
                <Splash override={override} />,
                <Cover override={override} />,
                <Timer />,
                <Standings />,
                <Event />,
                <Roster />
            ]
}

export default Stream
