import React, { useState, useEffect, useContext, useRef } from 'react'
import AppContext from '../../ctx'
import service from '../../service'

import './style.scss'

const calcDelta = (stamps, offset, periodDuration) => {
    if(!stamps || !stamps.length) {
        return null
    } else {
        const actual = stamps[stamps.length-1]
        const localOffset = offset - new Date().getTimezoneOffset()

        return actual + localOffset + (60*periodDuration*(stamps.length-1))
    }
}

const convertTime = input => {
    if(!isNaN(parseInt(input))) {
        const mins = Math.floor(parseInt(input)/60)
        const sec = (input+60)%60
        return `${mins < 10 ? '0' : ''}${mins}:${sec < 10 ? '0' : ''}${sec}`
    } else {
        return '00:00'
    }
}

const Timer = () => {
    const [subject, setSubject] = useState(null)
    const [home, setHome] = useState(null)
    const [away, setAway] = useState(null)
    const [colors, setColors] = useState(null)
    const [score, setScore] = useState(null)
    const [iteration, setIteration] = useState(0)
    const [timerState, setTimerState] = useState(false)
    const [displayTime, setDisplayTime] = useState(0)

    const ctx = useContext(AppContext)
    const { cover, standings, splash } = ctx.overlays
    const { coverData } = ctx.dataLayer

    const deltaRef = useRef()
    const workerRef = useRef()
    const subjectRef = useRef()
    const colorsRef = useRef()

    useEffect(() => {
        if(cover && cover.subj && !iteration) {
            console.log('Initialize timer')
            setSubject(cover.subj)
            subjectRef.current = cover.subj
            setIteration(1)
        }
    }, [cover])

    useEffect(() => {
        if(displayTime && displayTime%5 === 0) {
            actualize()
        }
    }, [displayTime])

    useEffect(() => {
        if(subject) {
            service('livestate', subject)
                .then(data => {
                    if(data) {
                        setHome(data.home)
                        setAway(data.away)
                        setScore(data.score)

                        setColors({
                            home: data.home.colors,
                            away: data.away.colors
                        })

                        if(!deltaRef || !deltaRef.current) {
                            console.log('No delta')
                            deltaRef.current = calcDelta(data.timer, data.appTimeOffset, data.periodDuration)
                        }

                        if(timerState !== data.timerState) {
                            setTimerState(data.timerState)
                        }
                    }
                })
        }
    }, [iteration])

    useEffect(() => {
        if(!workerRef.current) {
            workerRef.current = new Worker('/workers/ticker.js')
        }

        workerRef.current.onmessage = async msg => {
            if(msg.data && msg.data.action === 'tick') {
                if(timerState) {
                    deltaRef.current ++
                    setDisplayTime(deltaRef.current)
                } else {
                    actualize()
                }
            }
        }
    }, [timerState])

    const actualize = () => {
        if(subjectRef && subjectRef.current) {
            service('timerstate', subjectRef.current)
                .then(data => {
                    if(data) {
                        if(data.stamps && data.active !== timerState) {
                            if(data.active) {
                                deltaRef.current = calcDelta(data.stamps, data.clientOffset, coverData && coverData.meta ? coverData.meta.periodDuration || 30 : 30)
                            }

                            setTimerState(data.active)
                        }

                        setColors(data.colors)
                        setScore(data.score)
                    }
                })
        }
    }

    return  <div className='timer'>
                <div className={`widget ${(!cover && !splash) || (cover && cover.state) || (splash && splash.state) ? 'gone' : ''}`}>
                    <div className='brand'>
                        <div className='content' />
                    </div>

                    <div className='mean'>
                        <div className='team'>
                            {home ? [
                                <div className='colors'>
                                    <span style={{backgroundColor: colors ? colors.home.primary : 'var(--timer-bgd)'}}></span>
                                    <span style={{backgroundColor: colors ? colors.home.secondary : 'var(--timer-bgd)'}}></span>
                                </div>,
                                <span>{home.name}</span>
                            ] : null}
                        </div>

                        <div className='score'>
                            <div>{score ? score.home : '-'}</div>
                            <div>{score ? score.away : '-'}</div>
                        </div>

                        <div className='team'>
                            {away ? [
                                <div className='colors'>
                                    <span style={{backgroundColor: colors ? colors.away.primary : 'var(--timer-bgd)'}}></span>
                                    <span style={{backgroundColor: colors ? colors.away.secondary : 'var(--timer-bgd)'}}></span>
                                </div>,
                                <span>{away.name}</span>
                            ] : null}
                        </div>
                    </div>

                    <div className={`time ${timerState && displayTime ? 'shown' : ''}`}>
                        <span>{convertTime(displayTime)}</span>
                    </div>
                </div>
            </div>
}

export default Timer
