import React, { useContext, useEffect } from 'react'
import AppContext from '../../ctx'
import service from '../../service'

import './style.scss'

const Standings = () => {
    const ctx = useContext(AppContext)
    const { cover, standings } = ctx.overlays
    const { coverData, standingsData } = ctx.dataLayer

    useEffect(() => {
        if(standings && standings.state && !standingsData && coverData && coverData.meta.stageId && cover) {
            service('standings', coverData.meta.stageId, cover.subj)
                .then(data => {
                    if(data) {
                        ctx.setDataLayer({...ctx.dataLayer, standingsData: data})

                        setTimeout(() => {
                            ctx.setDataLayer({...ctx.dataLayer, standingsData: null})
                        }, 5000)
                    }
                })
        }
    }, [standings])

    return  standingsData ? (
                <div className={`standings ${standings && standings.state ? '' : 'gone'}`}>
                    <div className='widget'>
                        <div className='backdrop'>
                        </div>
                        <div className='body'>
                            {standingsData.map((row, i) => (
                                <div className={`widget-row ${row.highlight ? 'highlight' : ''}`} key={i}>
                                    <div className='mean'>
                                        <div className='pos'>{i+1}</div>
                                        <div className='emblem'>
                                            <img src={row.team.emblem} />
                                        </div>
                                        <div className='name'>{row.team.name}</div>
                                    </div>
                                    <div className='values'>
                                        <div className='pts'>{row.pts}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null
}

export default Standings
