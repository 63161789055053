import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../ctx'
import service from '../../service'

import './style.scss'

const labels = {
    goal: 'автор гола',
    yellowcard: 'предупреждение',
    secondyellow: 'удаление за 2жк',
    redcard: 'прямое удаление'
}

const Event = () => {
    const [data, setData] = useState(null)
    const [shown, setShown] = useState(false)

    const ctx = useContext(AppContext)
    const { cover, standings, event } = ctx.overlays
    const { coverData } = ctx.dataLayer

    useEffect(() => {
        if(event && event.subj) {
            service('event', event.subj, cover.subj)
                .then(data => {
                    if(data) {
                        setData(data)
                        setShown(true)
                    }
                })
        }
    }, [event])

    useEffect(() => {
        if(shown) {
            setTimeout(() => {
                setShown(false)
            }, 4500)

            setTimeout(() => {
                setData(null)
            }, 6000)
        }
    }, [shown])

    return  <div className={`event ${event && event.state ? '' : 'gone'}`}>
                {data ? (
                    <div className={`widget ${shown ? 'shown' : ''}`}>
                        <div className='picture'>
                            <img className='person' src={data.picture || ''} />
                        </div>

                        <div className='info'>
                            {coverData && coverData[data.side] ? (
                                <img className='emb' src={coverData[data.side].emblem} />
                            ) : null}
                            <div className='label'>{labels[data.type]}</div>
                            <div className='name'>
                                <span>{data.player.name} {data.player.surname}</span>
                            </div>
                            <div className='season-qty'>в турнире: {data.seasonQty}</div>
                        </div>
                    </div>
                ) : null}
            </div>
}

export default Event
