import React from 'react'
import { useHistory } from 'react-router-dom'

import { injectQueryPath } from '../query'

import './style.scss'

const controls = [
    {
        label: 'Сплэш',
        key: 'splash'
    },
    {
        label: 'Обложка',
        key: 'cover'
    },
    {
        label: 'Ростер',
        key: 'roster'
    },
    {
        label: 'Таблица',
        key: 'standings'
    },
    {
        label: 'Таймер',
        key: 'timer'
    },
    {
        label: 'Событие',
        key: 'event'
    }
]

const Controls = () => {
    const history = useHistory()

    return  <div className='controls'>
                {controls.map((c, i) => (
                    <div className='controls-group' key={i}>
                        <div className='label' onClick={() => history.push(`/dev/${c.key}/${window.location.search}`)}>{c.label}</div>
                        <button onClick={() => injectQueryPath(history, `${c.key}_state`, '1')}>Показать</button>
                        <button onClick={() => injectQueryPath(history, `${c.key}_state`, '0')}>Скрыть</button>
                    </div>
                ))}
            </div>
}

export default Controls
