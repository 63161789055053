import React, { useContext } from 'react'
import AppContext from '../../ctx'
import Lottie from 'react-lottie'

import * as animationData from './lotties/dots.json'

import './style.scss'

const Splash = ({ override }) => {
    const ctx = useContext(AppContext)
    const { splash } = ctx.overlays

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    }

    return  <div className={`splash ${splash && splash.state ? '' : 'gone'}`}>
                {override ? (
                    <video muted loop autoPlay playsInline id="override-video" src={override} width='100%' />
                ) : null}

                <div className='brand'>
                    <div className='brand-img'></div>
                </div>

                <Lottie options={defaultOptions} width='30vw' className='lottie' />
            </div>
}

export default Splash
