import React, { useContext, useEffect } from 'react'
import AppContext from '../../ctx'
import service from '../../service'

import './style.scss'

const Team = ({ data }) => {
    return  <div className='team'>
                <img src={data.emblem} />
                <div className='text'>
                    <div className='name'>{data.name}</div>
                    <div className='city'>{data.city}</div>
                </div>
            </div>
}

const Cover = ({ override }) => {
    const ctx = useContext(AppContext)
    const { cover } = ctx.overlays
    const { coverData } = ctx.dataLayer

    useEffect(() => {
        if(cover && cover.state && cover.subj && !coverData) {
            service('cover', cover.subj)
                .then(data => {
                    if(data) {
                        ctx.setDataLayer({...ctx.dataLayer, coverData: data})
                    }
                })
        }
    }, [cover])

    return  coverData ? (
                <div className={`cover ${cover && cover.state ? '' : 'gone'}`}>
                    {override ? (
                        <video muted loop autoPlay playsInline id="override-video" src={override} width='100%' />
                    ) : null}

                    <div className='brand-panel'>
                        <div className='top'>
                            <div className='tournament'>{coverData.meta.tournament}</div>
                            <div className='matchday'>{coverData.meta.matchday}</div>
                        </div>

                        <div className='brand'>
                            <div className='brand-img'></div>
                        </div>

                        <div className='bottom'>
                            <div className='time'>{coverData.meta.time}</div>
                            <div className='date'>{coverData.meta.date}</div>
                            <div className='location'>{coverData.meta.location}</div>
                        </div>
                    </div>

                    <div className='curtain'>
                        <Team data={coverData.home} />
                    </div>

                    <div className='curtain'>
                        <Team data={coverData.away} />
                    </div>
                </div>
            ) : null
}

export default Cover
